import React from "react"
import {
  faPhoneSquareAlt,
  faAt,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import {
  ContactUsHeader,
  ContactUsContainer,
  ContactDetailsDiv,
  ContactBoxDiv,
  ContactUsH2,
  ContactUsP,
  FontAwesome,
  Li,
  Ul,
} from "./HeaderStyle"
import iconPhone from "../../images/contact-us-phone.svg"
import iconMail from "../../images/contact-us-mail.svg"
import iconGlobe from "../../images/contact-us-globe.svg"
import bgSmartphone from "../../images/contact-us-big-smartphone.png"
import bgPhone from "../../images/contact-us-big-phone.png"
import styled from "styled-components"

function HeaderContactUs({ props, propsLink, propsData }) {
  const {
    Title,
    Title2,
    Desc,
    Phone,
    Wa,
    Op,
    Time,
    Email,
    Address1,
    Address2,
    Address3,
    Address4,
    Address5,
  } = propsData.Header
  return (
    <ContactUsContainer>
      <ContactUsMainDiv>
        <ContactUsH2>{Title}</ContactUsH2>
        <ContactUsP>{Desc}</ContactUsP>
        <ContactDetailsDiv>
          <ContactUsSmallDiv>
            <IconImg src={iconPhone} alt="icon" />
            <ContactH3>
              {Phone} <span style={{ color: "#828282" }}>{Wa}</span>
              <br />
              <span style={{ color: "#828282" }}>{Op}</span>
              <br />
              {Time}
            </ContactH3>
          </ContactUsSmallDiv>
          <ContactUsSmallDiv>
            <IconImg src={iconMail} alt="icon" />
            <ContactH3 textDecoration="underline">{Email}</ContactH3>
          </ContactUsSmallDiv>
          <ContactUsSmallDiv>
            <IconImg src={iconGlobe} alt="icon" />
            <ContactH3>
              {Address1} {Address2}
              <br />
              {Address3}, {Address4}
              <br />
              {Address5}
            </ContactH3>
          </ContactUsSmallDiv>
        </ContactDetailsDiv>
      </ContactUsMainDiv>
      <ImgLeft src={bgPhone} alt="header" />
      <ImgRight src={bgSmartphone} alt="header" />
    </ContactUsContainer>
  )
}

export default HeaderContactUs

const ContactUsMainDiv = styled.div`
  text-align: center;
  padding: 0px 15%;
`

const ContactUsSmallDiv = styled.div`
  display: flex;
  gap: 15px;
  justify-content: start;
`

const IconImg = styled.img`
  width: 30px;
`

const ContactH3 = styled.h3`
  font-family: "Typefez-black";
  color: #102c57;
  font-size: 16px;
  text-align: left;
  text-decoration: ${p => p.textDecoration};
`

const ImgLeft = styled.img`
  position: absolute;
  left: -100px;
  top: 100px;
  width: 400px;
  @media (max-width: 992px) {
    display: none;
  }
`
const ImgRight = styled.img`
  position: absolute;
  right: -120px;
  top: 80px;
  width: 400px;
  @media (max-width: 992px) {
    display: none;
  }
`